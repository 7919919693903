import React, {useEffect, useState} from 'react';
import {StaticImage} from "gatsby-plugin-image";
import {Trans} from "gatsby-plugin-react-i18next";
import Accordion from '../../../ui/legacy/Accordion/Accordion'
import ReactVideo from "../../../video-player/ReactVideo";

import posterHowToSetUpLock from '../../../../images/support/posters/aspire-ba/rfid/6GAspireRFID_BasicAdv_SharedUse-InitialSetUp.jpeg';
import posterHowToReplaceDeleteManagerCreds from '../../../../images/support/posters/aspire-ba/rfid/6GAspireRFID_BasicAdv_SharedUse-ReplaceDeleteManagerCredentials.jpeg';
import posterHowToOperateManagerCreds from '../../../../images/support/posters/aspire-ba/rfid/6GAspireRFID_BasicAdv_SharedUse-OperatewithManagercredentials.jpeg';
import posterHowToOperateRFIDCreds from '../../../../images/support/posters/aspire-ba/rfid/6GAspireRFID_BasicAdv_SharedUse-OperatewithRFIDCredentials.jpeg';
import posterHowToOperateProgrammingKey from '../../../../images/support/posters/aspire-ba/rfid/6GAspireRFID_BasicAdv_SharedUse-OperatewithProgrammingKey.jpeg';
import posterHowToAddManagerCreds from '../../../../images/support/posters/aspire-ba/rfid/6GAspireRFID_BasicAdv_SharedUse-AddManagerCredentials.jpeg';
import posterExpressRegister from '../../../../images/support/posters/aspire-ba/rfid/6GAspireRFID_BasicAdv_SharedUse-ExpressRegistrationManagerCreden.jpeg';

import assignAssignHowToProgramNewLock from '../../../../images/support/posters/aspire-ba/rfid/assigned/assigned-hot-to-progame.jpg';
import assignAddManagerKeysRfidCreds from '../../../../images/support/posters/aspire-ba/rfid/assigned/assigned-add-managerkeys-rfid-creds.jpg';
import assignHowToReplaceManagerKeys from '../../../../images/support/posters/aspire-ba/rfid/assigned/assigned-replace-mankeys-rfidcreds.jpg';
import assignAssignUserCredsWithKey from '../../../../images/support/posters/aspire-ba/rfid/assigned/assigned-assign-user-with-manager-key.jpg';
import assignExpressRegUserManagerKeys from '../../../../images/support/posters/aspire-ba/rfid/assigned/assigned-express-reg-user-and-manager.jpg';
import assignRemoveUserCredsManagerKey from '../../../../images/support/posters/aspire-ba/rfid/assigned/assigned-remove-user-creds-manager-key.jpg';
import assignOperateUserRfidCred from '../../../../images/support/posters/aspire-ba/rfid/assigned/assigned-operate-user-rfid-cred.jpg';
import assignOperateWithManagerKey from '../../../../images/support/posters/aspire-ba/rfid/assigned/assigned-operate-with-manager-key.jpg';
import assignOperateWithProgrammingKey from '../../../../images/support/posters/aspire-ba/rfid/assigned/assigned-operate-with-program-key.jpg';

import keypadAssignNewLock from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-program-new-lock.jpg';
import keypadAssignAddManager from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-add-manager-keys.jpg';
import keypadAssignReplaceManager from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-replace-manager.jpg';
import keypadAssignExpressManager from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-express-manager.jpg';
import keypadAssignUserCodes from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-assign-codes.jpg';
import keypadAssignExpressCodes from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-express-register.jpg';
import keypadAssignRemoveCreds from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-remove-creds.jpg';
import keypadAssignReplaceCodeWithCode from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-replace-code-with-code.jpg';
import keypadAssignOperateUserCode from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-operate-user-code.jpg';
import keypadAssignOperateManKey from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-operate-man-key.jpg';
import keypadAssignOperateProgKey from '../../../../images/support/posters/aspire-ba/keypad/assigned/assigned-keypad-operate-program-key.jpg';

import keypadSharedProgramLock from '../../../../images/support/posters/aspire-ba/keypad/shared/keypad-shared-program-lock.jpg';
import keypadSharedExpressReg from '../../../../images/support/posters/aspire-ba/keypad/shared/keypad-shared-express-register.jpg';
import keypadSharedAddManKeys from '../../../../images/support/posters/aspire-ba/keypad/shared/keypad-shared-add-man-keys.jpg';
import keypadSharedReplaceManKeys from '../../../../images/support/posters/aspire-ba/keypad/shared/keypad-shared-replace-man-keys.jpg';
import keypadSharedOperateWithCode from '../../../../images/support/posters/aspire-ba/keypad/shared/keypad-shared-operate-with-code.jpg';
import keypadSharedOperateWithManKey from '../../../../images/support/posters/aspire-ba/keypad/shared/keypad-shared-operate-manager-key.jpg';
import keypadSharedOperateWithProgKey from '../../../../images/support/posters/aspire-ba/keypad/shared/keypad-shared-operate-prog-key.jpg';

let dataSheetsKeypad = [
  {
    "title": "Aspire | Basic & Advanced | Keypad | Horizontal | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/3V7O9eettS5dF8xiOwOPeX/9a74d4ec9bb8731019df246bb00f3ff8/DS-D6AK-H-Bolt-Cable-DEN.pdf",
      "fileName": "DS-D6AK-H-Bolt-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | Keypad | Horizontal | Deadlatch | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/4QWZtOO1xgCFlaanyuzjI3/cc4e94e0a5149a880afd80c47ae7e09d/DS-D6AK-H-Latch-Cable-DEN.pdf",
      "fileName": "DS-D6AK-H-Latch-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | Keypad | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/757xIXD9XRhbDxbKDk2ynL/240c8fd86e704fb499ecb15260c20327/DS-D6AK-S-Bolt-Cable-DEN.pdf",
      "fileName": "DS-D6AK-S-Bolt-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | Keypad | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/1Fdt2Xf5LmPA0bX5cwMWoA/37320cb336ecd9000f7efe1a73e5ed72/DS-D6AK-S-Latch-Cable-DEN.pdf",
      "fileName": "DS-D6AK-S-Latch-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | Keypad | Vertical | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/2kyL3KcRYoy0EFnzTltA8B/cc3a63a87680d5afe1bd06e754fc5dbf/DS-D6AK-V-Bolt-Cable-DEN.pdf",
      "fileName": "DS-D6AK-V-Bolt-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | Keypad | Vertical | Deadlatch | Assigned",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/5cuDO60FpFTL7W09iTsPUK/04f6c172438394a1671fb89b0da9f448/DS-D6AK-V-Latch-Cable-DEN.pdf",
      "fileName": "DS-D6AK-V-Latch-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesKeypad = [
  {
    "title": "Aspire | Basic & Advanced | Keypad & RFID",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/31LaG1HExUqZ14MTN0UZgJ/497f61edf9fed9a7b20214503c27f468/IG-D6A-KR-DEN.pdf",
      "fileName": "IG-D6A-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesKeypad = [
  {
    "title": "Aspire | Basic & Advanced | Keypad | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/77ZXwUzt88njOiXijm18aD/e32ca202a58e59d1bf1b28547481b59b/PG-D6AKX-D-DEN.pdf",
      "fileName": "PG-D6AKX-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | Keypad | Deadlatch | Assigned",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/1nqhbC9zGiiyEV0LtGMKo6/cd7151bb98d426f79b7878b543d6dc69/PG-D6AKX-P-DEN.pdf",
      "fileName": "PG-D6AKX-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | Keypad | Deadlatch | Single Assigned",
    "file": {
      "url": "https://downloads.ctfassets.net/q5vaqi9g69zw/2tZ5KRbeT0oiQK2wgr98nS/5e86d5f194799bc50b8160bb233b92bc/PG-D6AKX-SINGLEASSIGNED-DEN.pdf",
      "fileName": "PG-D6AKX-SINGLEASSIGNED-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

let dataSheetsRFID = [
  {
    "title": "Aspire | Basic & Advanced | RFID | Narrow | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://cdn.mediavalet.com/usva/digilock/6x8T9OGpvEKbo1_JeZMBdQ/sZtz-CsAvU-GSjlluHzosQ/Original/DS-D6AR-N-Bolt-Cable-DEN.pdf",
      "fileName": "DS-D6ARN-N-Bolt-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | RFID | Standard | Deadlatch | Assigned",
    "file": {
      "url": "https://cdn.mediavalet.com/usva/digilock/Y7IYhcS1NkqSnaU-WKNwvg/L1S7CP6Zok2u85i3S8Qeog/Original/DS-D6AR-S-Latch-Cable-DEN.pdf",
      "fileName": "DS-D6ARN-N-Latch-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | RFID | Standard | Deadbolt | Shared or Assigned",
    "file": {
      "url": "https://cdn.mediavalet.com/usva/digilock/Sfx2jh2b7kmy7YaxAXoD9g/9lVxFFjlrkG8a0DY7gaDKw/Original/DS-D6AR-S-Bolt-Cable-DEN.pdf",
      "fileName": "DS-D6ARN-S-Bolt-Cable-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let installGuidesRFID = [
  {
    "title": "Keypad & RFID | Basic & Advanced",
    "file": {
      "url": "https://assets.ctfassets.net/q5vaqi9g69zw/31LaG1HExUqZ14MTN0UZgJ/497f61edf9fed9a7b20214503c27f468/IG-D6A-KR-DEN.pdf",
      "fileName": "IG-D6A-KR-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];
let productGuidesRFID = [
  {
    "title": "Aspire | Basic & Advanced | RFID | Deadbolt | Shared Use",
    "file": {
      "url": "https://cdn.mediavalet.com/usva/digilock/jD2-ssi-e06XFxQOZA25cQ/Zur05gXkaUCYEO-PyrCXtA/Original/PG-D6ARX-D-DEN.pdf?devmode=true",
      "fileName": "PG-D6ARN-D-DEN.pdf",
      "contentType": "application/pdf"
    }
  },
  {
    "title": "Aspire | Basic & Advanced | RFID | Deadlatch | Assigned Use",
    "file": {
      "url": "https://cdn.mediavalet.com/usva/digilock/TWuEqbRgRkOHgnoeAfA5jA/2RIP63noRES9k0qxWYl7Bw/Original/PG-D6ARX-P-DEN.pdf?devmode=true",
      "fileName": "PG-D6ARN-P-DEN.pdf",
      "contentType": "application/pdf"
    }
  }
];

const Tab = (props) => {
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const getActiveClass = (index, className) => {
    return toggleState === index ? className : '';
  };

  useEffect(() => {
    if (document.location.hash === '#rfid') {
      setToggleState(2);
    }
  }, [])

  return (
      <div className={'tab-container'}>
        <ul className="tab-list unstyled-list">
          <li
              className={getActiveClass(1, 'active')}
              onClick={() => toggleTab(1)}
          >
            Aspire <Trans>keypad</Trans>
          </li>
          <li
              className={getActiveClass(2, 'active')}
              onClick={() => toggleTab(2)}
          >
            Aspire <Trans>rfid</Trans>
          </li>
        </ul>
        <div className="tab-content-container">
          <div className={"tab-content "+getActiveClass(1, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/aspire-locks.png'}
                  loading={'lazy'}
                  width={790}
                  height={750}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Aspire Electronic Lock"
              />
              <StaticImage
                  src={'../../../../images/shared/motorized-rear-units.png'}
                  loading={'lazy'}
                  width={466}
                  height={322}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Rear Units"
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          dataSheetsKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesKeypad.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Aspire How To Videos">

                    <Accordion clsName={'next-support'}>
                      <div label={'Assigned Use'}>
                        <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={keypadAssignNewLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/jFB33yfWNE--bFC66E21uQ/FYqAsZhlUUWxnn2fDasYYw/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Assigned%20Use-01_How%20to%20Program%20a%20New%20Lock.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignAddManager}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/spsSve1M1kyr_KxeYrni1g/CYtYXPI_3EKGVMotOZUeQw/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Assigned%20Use-02_How%20to%20Add%20Manager%20Keys.mp4'}
                            />
                            <p>How to add manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignExpressManager}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/LL9w7Jq5PUyKfuc2yMvXgw/5Ilm0wpvxkGZBIJdqXMCNg/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Assigned%20Use-02_How%20to%20Express%20Register%20Manager%20Keys.mp4'}
                            />
                            <p>How to express register manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignReplaceManager}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/9t7MhLdV3E-wFkPzfVbJMw/bYsOZAjH1UmyNNY85obSZg/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Assigned%20Use-03_How%20to%20Replace%20Manager%20Keys.mp4'}
                            />
                            <p>How to replace manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignUserCodes}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/f1Tye2Neh06GYRip-mSjjA/2bTdgbcUh0itJ5x0gEAS9Q/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Assigned%20Use-04_How%20to%20Assign%20User%20Codes.mp4'}
                            />
                            <p>How to assign user codes</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignExpressCodes}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/2kSKrzhhxUWPF9PbHQ-ASg/dj24aOKbXUC_fF-Zh33sHg/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Assigned%20Use-05_How%20to%20Express%20Register%20User%20Credentials%20and%20Manager%20Keys.mp4'}
                            />
                            <p>How to express register user credentials and manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignRemoveCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/xzq_cXzgt0qQfMvQ6vCdLg/SznvFs8dUUeTnVdTgpEFsA/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Assigned%20Use-06_How%20to%20Remove%20User%20Credentials.mp4'}
                            />
                            <p>How to remove user credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignReplaceCodeWithCode}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/w-bY0aUBwk6i5N2YHPyrmg/RK026i5H-EOR82GMgevVzw/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Assigned%20Use-07_How%20to%20Replace%20an%20Assigned%20User%20Code%20with%20a%20New%20User%20Code.mp4'}
                            />
                            <p>How to replace an assigned user code with </p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignOperateUserCode}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/T9oos4bdxU-T7w24JRa2YA/ndqI9KbnyUSdCgMMMmyseA/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_AssignedUse-08_How%20to%20Operate%20with%20a%20User%20Code.mp4'}
                            />
                            <p>How to operate with a user code</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignOperateManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/AeOBO1Kp30ClWFrshLoYZQ/xxCiSVNHwEKODIXJDZLnYw/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_AssignedUse-09_How%20to%20Operate%20with%20a%20Manager%20Key.mp4'}
                            />
                            <p>How to override with a manager key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadAssignOperateProgKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/KqMDXFFCJU2XKB-B-MEKMw/kQGIKc_7Fk6kPCPQRS42vw/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_AssignedUse-10_How%20to%20Operate%20with%20the%20Programming%20Key.mp4'}
                            />
                            <p>How to override with a programming key</p>
                          </div>
                        </div>
                      </div>
                      <div label={'Shared Use'}>
                        <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={keypadSharedProgramLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/edFAWhTpiUm-N9DDHWgPBg/u2YMk71BykGvh-ulcZV-mw/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Shared%20Use-01_How%20to%20Program%20a%20New%20Lock.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadSharedExpressReg}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/PbYXjcJjI0u1VGx-kxzj_g/xtf4dUhWO0229LGhpp3HBg/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Shared%20Use-02_How%20to%20Express%20Register%20Manager%20Keys.mp4'}
                            />
                            <p>How to express register manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadSharedAddManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/BHd6ZDRQdk62cTC22vxOQg/uViWQ1IMVkKkPEfAQrwU2Q/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Shared%20Use-03_How%20to%20Add%20Manager%20Keys.mp4'}
                            />
                            <p>How to add manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadSharedReplaceManKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/taqOnWq6tkyRn4QHnUmftw/TlnKqk3WBUSGjudAwJRZrg/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_Shared%20Use-04_How%20to%20Replace%20Manager%20Keys.mp4'}
                            />
                            <p>How to replace manager keys</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadSharedOperateWithCode}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/_CA0H0g24UWCfE9_ngq7-Q/bsdm4LiBOE2staGyVfKtAQ/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_SharedUse-05_How%20to%20Operate%20with%20a%20User%20Code.mp4'}
                            />
                            <p>How to operate with a user code</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadSharedOperateWithManKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/b1UBdvxoGkSxEEfmD8Wa7w/JO_qddh-TkCJo0PaCRDiaQ/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_SharedUse-06_How%20to%20Operate%20with%20a%20Manager%20Key.mp4'}
                            />
                            <p>How to override with a manager key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={keypadSharedOperateWithProgKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/_aHitWz9bECdmF6mQuPo2w/hc3ICsvp8k2iIPktiKh5Ew/Widescreen720p/6G%20Aspire%20Keypad_BasicAdv_SharedUse-07_How%20to%20Operate%20with%20the%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>
                        </div>
                      </div>
                    </Accordion>

                  </div>
                </Accordion>
              </div>
            </div>
          </div>
          <div className={"tab-content "+getActiveClass(2, 'active')}>
            <div className="front-rear">
              <StaticImage
                  src={'./images/aspire-locks-rfid.png'}
                  loading={'lazy'}
                  width={790}
                  height={750}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Aspire Electronic Lock"
              />
              <StaticImage
                  src={'../../../../images/shared/motorized-rear-units.png'}
                  loading={'lazy'}
                  width={466}
                  height={322}
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Rear Units"
              />
            </div>
            <div className="lists">
              {/*DOCUMENTS*/}
              <div label="Document Library">
                <Accordion clsName={'next-support'}>
                  <div label="Data Sheets">
                    <div className="step-images">
                      <ul>
                        {
                          dataSheetsRFID.map((doc, index) => (
                                  <li key={index}>
                                    <li key={index}>
                                      <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                    </li>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Install Guides">
                    <div className="step-images">
                      <ul>
                        {
                          installGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Product Guides">
                    <div className="step-images">
                      <ul>
                        {
                          productGuidesRFID.map((doc, index) => (
                                  <li key={index}>
                                    <a href={doc.file.url} target={'_blank'} rel={'noreferrer'}>{doc.title}</a>
                                  </li>
                              )
                          )
                        }
                      </ul>
                    </div>
                  </div>
                  <div label="Aspire How To Videos">

                      <Accordion clsName={'next-support'}>
                        <div label={'Assigned Use'}>
                          <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={assignAssignHowToProgramNewLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/ignzw4AIl0CrcPUNWjNjvA/xmim6Bp92EinEBnTIBbiRw/Widescreen720p/6G%20Aspire%20RFID_BasicAdv_Assigned%20Use-01_How%20to%20Program%20a%20New%20Lock.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={assignAddManagerKeysRfidCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/q8BPqELo2EGaKol9P_NDsw/ybyYfB5cOE2KKJKSihbkIw/Widescreen720p/6G%20Aspire%20RFID_BasicAdv_Assigned%20Use-02_How%20to%20Add%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>Add manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={assignHowToReplaceManagerKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/MfSnfVJ1QkCGAwZKB_hupQ/LZp1C3xqPUSM4junEYW-OA/Widescreen720p/6G%20Aspire%20RFID_BasicAdv_Assigned%20Use-03_How%20to%20Replace%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>How to replace manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={assignAssignUserCredsWithKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/e8TYC43z4kOALscTUfU5_Q/Db6oVQaezEKm8svm7nauTA/Widescreen720p/6G%20Aspire%20RFID_BasicAdv_Assigned%20Use-04_How%20to%20Assign%20User%20Credentials%20with%20a%20Manager%20Key-RFID%20Credential.mp4'}
                            />
                            <p>How to assign user credentials with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={assignExpressRegUserManagerKeys}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/5yku6QBGCUqN6bzfl92_hg/lPwzcyYDdUidPBxKHCKioQ/Widescreen720p/6G%20Aspire%20RFID_BasicAdv_Assigned%20Use-05_How%20to%20Express%20Register%20User%20and%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>How to express register user and manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={assignRemoveUserCredsManagerKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/wo6LRpe7GUWwTe92gvTSkg/l_PK_E74M0KzjkvDOX3ohg/Widescreen720p/6G%20Aspire%20RFID_BasicAdv_Assigned%20Use-06_How%20to%20Remove%20User%20Credentials%20with%20Manager%20Keys-RFID%20Credentials.mp4'}
                            />
                            <p>How to remove user credentials with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={assignOperateUserRfidCred}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/JvgOIWjYpEmQH7xw9fCSxQ/HHLgMLjyRESDfvylGYw88Q/Widescreen720p/6G%20Aspire%20RFID_BasicAdv_Assigned%20Use-07_How%20to%20Operate%20with%20a%20User%20RFID%20Credential.mp4'}
                            />
                            <p>How to operate with a user RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={assignOperateWithManagerKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/Jlc4Zow3N0CgHfXaBpuVZw/2DRwjZqc5Eu3PRH0-B-jxg/Widescreen720p/6G%20Aspire%20RFID_BasicAdv_Assigned%20Use-08_How%20to%20Operate%20with%20a%20Manager%20Key-RFID%20Credential.mp4'}
                            />
                            <p>How to override with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={assignOperateWithProgrammingKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/pvl16gmHlE-3W_IOm_PohQ/9R9lFLNyWUiEsnYSpyvF2Q/Widescreen720p/6G%20Aspire%20RFID_BasicAdv_Assigned%20Use-09_How%20to%20Operate%20with%20the%20Programming%20Key.mp4'}
                            />
                            <p>How to override with the programming key</p>
                          </div>
                          </div>
                        </div>
                        <div label={'Shared Use'}>
                          <div className={'aspire-how-to-vids'}>
                          <div>
                            <ReactVideo
                                poster={posterHowToSetUpLock}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/6Df5j5Q4HkyHjUfBB5PbLA/ul2xrUM9LE2DK2zgKfbnyA/Widescreen1080p/6G%20Aspire%20RFID_BasicAdv_Shared%20Use%20-%20Initial%20Set%20Up.mp4'}
                            />
                            <p>How to program a new lock</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={posterHowToReplaceDeleteManagerCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/CbXUWekTyEaRRE01GIzF1g/hfEVyoHxIU-o3FS_YELGjw/Widescreen1080p/6G%20Aspire%20RFID_BasicAdv_Shared%20Use%20-%20ReplaceDelete%20Manager%20Credentials.mp4'}
                            />
                            <p>How to replace manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={posterHowToOperateManagerCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/DnQJ72cQeEKgyS07jdXREQ/_M2XYF0bXUuYUy_bS4iCWA/Widescreen1080p/6G%20Aspire%20RFID_BasicAdv_Shared%20Use%20-%20Operate%20with%20Manager%20credentials.mp4'}
                            />
                            <p>How to override with a manager key/RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={posterHowToOperateRFIDCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/yxVAMbIlFUiqdl8b0Tr8VQ/QecI7h6EF06zE50IpRtY4A/Widescreen1080p/6G%20Aspire%20RFID_BasicAdv_Shared%20Use%20-%20Operate%20with%20RFID%20Credentials.mp4'}
                            />
                            <p>How to operate with a user RFID credential</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={posterHowToOperateProgrammingKey}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/nggrubxtoEOAsJ9GHDKhyw/bK3CTRedhEyCOryWf0XWkw/Widescreen1080p/6G%20Aspire%20RFID_BasicAdv_Shared%20Use%20-%20Operate%20with%20Programming%20Key.mp4'}
                            />
                            <p>How to override with programming key</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={posterHowToAddManagerCreds}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/vCdOWjWUD0iNfHwia0AHLw/JX6vQPtTpUe10HYBRTVACQ/Widescreen1080p/6G%20Aspire%20RFID_BasicAdv_Shared%20Use%20-%20Add%20Manager%20Credentials.mp4'}
                            />
                            <p>How to add manager keys/RFID credentials</p>
                          </div>
                          <div>
                            <ReactVideo
                                poster={posterExpressRegister}
                                vURL={'https://cdn.mediavalet.com/usva/digilock/grZGuSddC0S5oSJ9GMM3lg/Xp2dAm3ckUiMF0QwBbV5-Q/Widescreen1080p/6G%20Aspire%20RFID_BasicAdv_Shared%20Use%20-%20Express%20Registration%20Manager%20Credentials.mp4'}
                            />
                            <p>How to express register manager keys/RFID credentials</p>
                          </div>
                          </div>
                        </div>
                      </Accordion>

                  </div>
                </Accordion>
              </div>
            </div>
          </div>
        </div>

      </div>
  );
};

export default Tab;